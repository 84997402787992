import { ReactComponent as IconDesign } from "../icons/responsive-design-hand.svg"
import { ReactComponent as IconDispatch } from "../icons/cloud-monitor-download.svg"
import { ReactComponent as IconGPS } from "../icons/antenna-1.svg"
import { ReactComponent as IconInventory } from "../icons/car-actions-settings.svg"
import { ReactComponent as IconMobileApp } from "../icons/phone-action-wifi-1.svg"
import { ReactComponent as IconDriverCheck } from "../icons/single-neutral-actions-check-1.svg"
import { ReactComponent as IconAcceptCall } from "../icons/cloud-smartphone-download.svg"
import { ReactComponent as IconChecklist } from "../icons/checklist-pen.svg"
import { ReactComponent as IconDirectBilling } from "../icons/cash-molecule.svg"
import { ReactComponent as IconCashCloud } from "../icons/cloud-cash.svg"
import { ReactComponent as IconWorkflow } from "../icons/accounting-calculator-2.svg"
import { ReactComponent as IconIntegration } from "../icons/network-cash.svg"
import { ReactComponent as IconPerformance } from "../icons/performance-increase-1.svg"
import { ReactComponent as IconTruckVolume } from "../icons/pie-line-graph-desktop.svg"
import { ReactComponent as IconDriversMoney } from "../icons/monetization-team.svg"
import { ReactComponent as IconTaxReport } from "../icons/accounting-invoice-hand.svg"

export const featureGroups = [
  {
    name: "Simple, Powerful Dispatching",
    media: "/screenshots/simple-but-powerful-dispatching.png",
    description:
      "Our software was built with you in mind; save countless hours on training staff with our user-friendly interface and intuitive design. Accepting calls, assigning calls, tracking job progress, and managing vehicle inventory just got a whole lot easier!",
    features: [
      {
        name: "Intuitive Design",
        icon: IconDesign,
        description:
          "Our platform was built with you in mind. Towbook is intuitive and straight-forward, which means you won't have to guess where to go or what to do! You and your staff will save countless hours on training with our sleek and user-friendly interface.",
      },
      {
        name: "Digital Dispatching & Email Processing",
        icon: IconDispatch,
        description:
          "Never enter a call from a motor club manually again. With Digital Dispatching and Email Processing, you'll be able to receive calls from: AAA, Agero, Allied Dispatch, Allstate, American Access Casualty, Auto Help Line, AXA Assistance, Cars Arrive, Copart, FleetNet, Geico, Gerber NCS, Honk, IAA, Lease Plan USA, National Auto Club, Nation Safe Drivers, Navistar, Netcost, Pinnacle/Coachnet, Progressive, Quest, Road America, Roadside Protect, Swoop, Tesla, Transit Pros, Urgently, and United States Auto Club.",
      },
      {
        name: "GPS Tracking Capability",
        icon: IconGPS,
        description:
          "See the location of your drivers by using our iOS and Android mobile apps, or use one of our integrated GPS providers. GPS integrations include: AT&T Fleet Complete, Azuga, DriverLocate, Samsara, US Fleet Tracking, Verizon Connect, and Webfleet.",
      },
      {
        name: "Manage Vehicle Inventory",
        icon: IconInventory,
        description:
          "Effortlessly record and manage your impounded/stored vehicles using Towbooks' Impound Manager. We even have your state required letters built-in or take advantage of our integration with Auto Data Direct.",
      },
    ],
  },
  {
    name: "Powerful Mobile Apps",
    media: "/screenshots/powerful-mobile-apps.png",
    description:
      "Use Towbook from any device! iPhone, Android, etc. You can use it on as many devices as you want to for no extra fee.",
    features: [
      {
        name: "Android/iPhone Compatibility",
        icon: IconMobileApp,
        description:
          "With applications for Android and iPhone devices, drivers are able to accept calls dispatched to them right on the mobile app!",
      },
      {
        name: "Equipment Inspections/Driver Check-In",
        icon: IconDriverCheck,
        description:
          "Your drivers can check-in to indicate that they are on the clock and also complete equipment inspection reports that are automatically sent to managers for review.",
      },
      {
        name: "Accept Motor Club Calls Digitally",
        icon: IconAcceptCall,
        description:
          "You don't have to be by a computer to accept calls with Towbook; they'll come directly to your mobile application.",
      },
      {
        name: "Minimize Damage Claims",
        icon: IconChecklist,
        description:
          "Your drivers are able to update their status, attach geocoded photos to a call, update call location using their GPS coordinates, collect customer signatures, indicate method of payment, create a vehicle damage report, email/text a receipt, and much more!",
      },
    ],
  },
  {
    name: "Billing Made Easy",
    media: "/screenshots/sophisticated-billing.png",
    description:
      "Our billing features include the ability to invoice your motor clubs directly, automatically import payments, integrating with QuickBooks, and much more! With our intuitive workflow, your staff will save countless hours on billing.",
    features: [
      {
        name: "Motor Club Direct Billing",
        icon: IconDirectBilling,
        description:
          "Towbook greatly reduces your workload by allowing you to submit your invoices to several major motor clubs for payment;  your billing department will thank you!",
      },
      {
        name: "Payment Importing",
        icon: IconCashCloud,
        description:
          "Turn on payment importing to make your life even easier! We'll import payment information from the motor club and even mark them paid for you!",
      },
      {
        name: "Advanced Accounting Workflow",
        icon: IconWorkflow,
        description:
          "Towbook has an intuitive accounting workflow that will allow you to manage what invoices have been billed, what is paid, or unpaid. You can even mass-generate statements for every account; you'll then have the option send them via e-mail or mail them to your customers!",
      },
      {
        name: "QuickBooks Integration",
        icon: IconIntegration,
        description:
          "Reduce your double-entry work by connecting Towbook to your QuickBooks Desktop or Online application.",
      },
    ],
  },
  {
    name: "Comprehensive Reporting",
    media: "/screenshots/comprehensive-and-customizable-reporting.png",
    description:
      "With hundreds of options, our flexible reports make it easy for you to review company performance. See your daily activity, generate commissions, manage receivables, view truck expenses, and much more!",
    features: [
      {
        name: "Company Performance",
        icon: IconPerformance,
        description:
          "We'll make it simple for you to see how many calls you've completed and your revenue and income generated for a specific period of time.",
      },
      {
        name: "Analyze Driver/Truck Volume",
        icon: IconTruckVolume,
        description:
          "See which of your trucks and drivers are the most productive for your business and which ones bring in the most revenue.",
      },
      {
        name: "Driver Commissions",
        icon: IconDriversMoney,
        description:
          "If you pay driver commissions, you'll be able to access them on-demand at any point in time. ",
      },
      {
        name: "Sales Tax Reporting",
        icon: IconTaxReport,
        description:
          "We'll keep track of your sales tax and create a report for you automatically, saving lots of time when it's tax season!",
      },
    ],
  },
]
